.App {
  text-align: center;
  background-color: rgb(230, 246, 252);
  color: rgb(230, 246, 252);
  height: 200vh;
}

.home{
  background-color: rgb(230, 246, 252);
}

.refresh{
  color: rgb(8, 8, 8);
  padding: 0px 0px 0px 0px;
  text-align: right;
}

.serverFault{
  color: red;
  padding: 0px 0px 0px 0px;
  text-align: center;
  font-weight: 500;
}

.bug{
  color:navy;
  padding: 0px 0px 0px 1050px;
  text-align: right;
  font-weight: 600;
  font-size: 14px;
}

.h1{
  color: rgb(8, 8, 8);
}

Nav{
  display:flex; 
  height:45px;
  /* background: rgb(248, 137, 33); */
  background:rgb(15, 32, 78);
  color: rgb(230, 246, 252);
  padding: 0px 0px 0px 0px;
}

.leftIntend{
  padding: 12px 4px 0px 650px;
  font-weight: 400;
  font-size: 17px;
}

.leftIntend1{
  padding: 10px 10px 0px 40px; 
  font-weight: 600;
  font-size: 20px;
}

.nav-item{
  color:white; 
 
}

.navbar-brand {
 color: #fff;
 font-size: 1.2rem;
 font-family: Amazon Ember Light,Helvetica Neue,Arial,Helvetica,Roboto,sans-serif;
 font-weight: 900;
 padding: 5px 0px 2px 19px;
 margin-left: -510px;
}

.navbar-user{
 color: #fff;
 font-size: 0.9rem;
 font-family: Amazon Ember,Helvetica Neue,Arial,Helvetica,Roboto,sans-serif;
 font-weight: 500;
 padding: 5px 0px 2px 19px;
}

.navbar-user:hover,.navbar-brand:hover {
 color: silver;
}

.navbar-icon {
 width: 28px;
 padding: 5px 2px 9px 2px;
}

.card-img, .card-img-top, .card-img-bottom {
  flex-shrink: 0;
  border-radius:100px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  padding: 3px 0px 0px 0px;
}


.indent{
  padding-left: 50px;
  padding-right: 50px;
  max-width: 92vw;
  max-height: 85vh;
  position: relative;
}

.nocard {
  padding-left: 50px;
  padding-right: 50px;
  max-width: 92vw;
  max-height: 700;
  position: relative;
  border-radius: 3px;
  margin: auto;
  padding: 10px 10px 10px 10px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.377) ;
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.377) ;
-webkit-transition: .5s;
transition: .5s;
transform: scale(1.0);
box-shadow: 0 0 4px 1px rgba(20, 20, 20, 0.8);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: left;
  background-color:silver; 
}

.card {
  position: relative;
  max-width: 92vw;
  max-height: 85vh;
  height: 80vh;
  border-radius: 3px;
  margin: auto;
  padding: 10px 10px 10px 10px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.377) ;
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.377) ;
-webkit-transition: .5s;
transition: .5s;
transform: scale(1.0);
box-shadow: 0 0 4px 1px rgba(20, 20, 20, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: left;
  background-color:silver; 
}

.card1 {
  position: relative;
  max-width: 92vw;
  height: auto;
  border-radius: 3px;
  margin: auto;
  padding: 10px 10px 10px 10px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.377) ;
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.377) ;
-webkit-transition: .5s;
transition: .5s;
transform: scale(1.0);
box-shadow: 0 0 4px 1px rgba(20, 20, 20, 0.8);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: left;
  background-color:silver; 
}